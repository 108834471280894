import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import {
  AppointmentsResponseModel,
  DashboardStatistic,
  RegistrationStatisticsRequestModel,
  RegistrationStatisticsResponseModel,
} from '../model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  public statistics(): Observable<DashboardStatistic> {
    return this.http.get<DashboardStatistic>(`${environment.apiEndpoint}/b2b/dashboard/stream`);
  }

  public getRegistrationStatistics(
    registrationRequest: RegistrationStatisticsRequestModel,
  ): Observable<RegistrationStatisticsResponseModel> {
    return this.http.get<RegistrationStatisticsResponseModel>(
      `${environment.apiEndpoint}/b2b/dashboard/registrations?from=${registrationRequest.from}&to=${registrationRequest.to}`,
    );
  }

  public getAppointmentsSchedule(): Observable<AppointmentsResponseModel[]> {
    return this.http.get<AppointmentsResponseModel[]>(`${environment.apiEndpoint}/b2b/livecalls`);
  }
}
