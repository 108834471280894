import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { PartnerLinksModel, PartnerLinksPayloadModel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class PartnerLinksService {
  constructor(private http: HttpClient) {}

  public getPartnerLinks(): Observable<PartnerLinksModel[]> {
    return this.http.get<PartnerLinksModel[]>(`${environment.apiEndpoint}/b2b/partnerlinks`);
  }

  public postPartnerLinks(body: PartnerLinksPayloadModel): Observable<void> {
    return this.http.post<void>(`${environment.apiEndpoint}/b2b/partnerlinks`, body);
  }
}
