export class PartnerLinksModel {
  description: string;
  id: number;
  link: string;
  partnerLinkType: string;
  title: string;
}

export class PartnerLinksPayloadModel {
  partnerLinkId: number;
  link: string;
}
