import { Component, EventEmitter, Inject, isDevMode, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { AdvisorsService, AUTH_SERVICE_TOKEN, AuthService } from '../../shared/service';

import { Advisor } from '../../shared/model';

import { Groups } from '../../shared/enum/groups.enum';
import { Roles } from '../../shared/enum/roles.enum';

@Component({
  selector: 'app-user-sidebar-modal',
  templateUrl: 'user-sidebar-modal.component.html',
  styleUrls: ['user-sidebar-modal.component.scss'],
})
export class UserSidebarModalComponent implements OnInit {
  @Output() close = new EventEmitter();

  protected devMode: boolean = false;
  protected highestPriorityGroup: Groups | null = null;

  protected advisor: Advisor;
  protected pin: string = '';

  constructor(
    @Inject(AUTH_SERVICE_TOKEN) protected readonly authService: AuthService,
    private readonly advisorsService: AdvisorsService,
    private readonly toasterService: ToastrService,
    private readonly bsModalRef: BsModalRef,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.advisorsService.getAdvisorProfile().subscribe({
      next: (advisor: Advisor): void => {
        this.advisor = advisor;
        this.getInfoAfterGettingsAdvisor();
      },
      error: (error: Error): void => {
        console.log(error);
      },
    });
  }

  private getInfoAfterGettingsAdvisor(): void {
    if (this.advisor?.pin) {
      this.pin = this.advisor.pin;
    }

    if (isDevMode()) {
      this.devMode = true;
    }
  }

  protected navigateTo(route: string): void {
    this.closeFlyoutMenu();
    this.router.navigate([route]);
  }

  protected navigateToMediaStudio(): void {
    if (this.authService.hasRole(Roles.MEDIA_STUDIO)) {
      this.router.navigate(['/media-studio']);
    } else {
      this.router.navigate(['/profile/restricted-product/media-studio']);
    }

    this.closeFlyoutMenu();
  }

  protected navigateToPool(): void {
    if (this.authService.hasRole(Roles.POOL_CONNECTION)) {
      this.router.navigate(['/pools']);
    } else {
      this.router.navigate(['/profile/restricted-product/pool-connections']);
    }

    this.closeFlyoutMenu();
  }

  protected closeFlyoutMenu(): void {
    this.bsModalRef.hide();
  }

  protected goToOtherExternalUrl(url: string): void {
    window.open(url, '_blank');
    this.closeFlyoutMenu();
  }

  protected copyPin(): void {
    navigator.clipboard.writeText(this.advisor.pin);
    this.callToastrMsg('PIN in die Zwischenablage kopiert');
  }

  private callToastrMsg(msg: string): void {
    this.toasterService.info(msg);
  }

  protected logout(): void {
    this.authService.logout();
  }

  protected readonly isDevMode = isDevMode;
  protected readonly Groups = Groups;
  protected readonly Roles = Roles;
  protected readonly innerWidth = innerWidth;
}
